import React from "react";
import Plotly from "plotly.js-basic-dist";
import createPlotlyComponent from "react-plotly.js/factory";

import useProtectedJson from "./useProtectedJson";
import "./App.css";

const Plot = createPlotlyComponent(Plotly);

function App() {
  let [data, Fallback] = useProtectedJson(
    "/results/SECRET/correct_bolus_pseudonymised.py.json"
  );
  if (!data) return Fallback;

  let plotData = data.plots[0];
  return (
    <div className="App">
      <Plot
        data={[
          {
            x: plotData.x,
            y: plotData.y,
            type: "scatter",
            mode: "lines+markers",
            marker: { color: "red" },
          },
        ]}
        layout={{ width: 320, height: 240, title: "A Fancy Plot" }}
      />
    </div>
  );
}

export default App;
