import React, { useState, useEffect } from "react";
import { sha256 } from "js-sha256";

const SECRET_STORAGE_KEY = "_useProtectedJson_secret";

function computeSecretFromPassword(password) {
  return sha256.hex(password).slice(0, 16);
}

export default function useProtectedJson(path) {
  let [password, setPassword] = useState("");

  let [data, isLoading] = useAwait(async () => {
    let secret = localStorage.getItem(SECRET_STORAGE_KEY);
    let hasSecret = !!secret;
    if (!hasSecret) {
      if (!password) return null;
      secret = computeSecretFromPassword(password);
      console.log("computed secret", secret);
    }

    let secretPath = path.replace("SECRET", secret);
    let res = await fetch(secretPath, {
      headers: { Accept: "application/json" },
    });
    if (!res.ok) return null;
    try {
      let data = await res.json();
      if (!hasSecret) {
        console.log("stored secret", secret);
        localStorage.setItem(SECRET_STORAGE_KEY, secret);
      }
      return data;
    } catch {
      if (hasSecret) {
        console.log("delete secret");
        localStorage.removeItem(SECRET_STORAGE_KEY);
      }
      return null;
    }
  }, [password]);

  if (!data && isLoading) return [data, null];
  if (!data) return [data, <PasswordInput {...{ password, setPassword }} />];

  return [data, false];
}

function PasswordInput({ password, setPassword }) {
  return (
    <div style={{ margin: "16px" }}>
      <label htmlFor="password">Passwort:</label>
      <br />
      <input
        id="password"
        type="text"
        value={password}
        onChange={({ target: { value } }) => setPassword(value)}
      />
    </div>
  );
}

function useAwait(asyncCallback, refreshArray, init) {
  let [value, setValue] = useState(init);
  let [isLoading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      try {
        setValue(await asyncCallback());
      } catch (err) {
        console.log("error awaiting value", err);
      }
      setLoading(false);
    })();
    // eslint-disable-next-line
  }, refreshArray || undefined);
  return [value, isLoading];
}
